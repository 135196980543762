.pointer {
  cursor: pointer;
}

.inline {
  display: inline;
}

.checkboxModal {
  width: 20px;
  height: 20px;
}

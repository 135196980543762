.playIcon {
  margin: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgb(126, 119, 119);
  padding: 2px 10px;
  border-radius: 0.375rem;
  cursor: pointer;

  &_desc {
    margin-left: 6px;
  }

  &:hover {
    color: white;
    background-color: #CD201F;
    border: 1px solid white;
  }

  transition: .3s;
}
@import "assets/base";

//VIDEO COMPONENT STYLES
@import "./styles/components/video";

@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.header-center .ag-header-cell-label  {
  justify-content: center;
}

.required-height {
  height: 37px !important;
}


.pagination {
  @extend .d-flex, .flex-wrap, .justify-content-center;
}

::ng-deep .p-toast, .p-component, .p-toast-top-right {
  top: 12% !important;
}

//HEADER CLASS FOR FIN/REPORTS/RECONCILIATION
.header_general_data_reconciliation {
  background: rgb(255 189 101);
}

.header_additional_reconciliation {
  background: rgb(181 231 255);
}

.w-full {
  width: 100%;
}

.pointer {
  cursor: pointer;
}

.flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.justify-between {
  justify-content: space-between;
}

.items-center {
  align-items: center;
}

// TEXT
.threeDots {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}